import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import {
  Widget,
  addResponseMessage,
  addUserMessage,
  deleteMessages,
  renderCustomComponent,
  setQuickButtons,
  toggleInputDisabled,
  toggleMsgLoader,
} from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
import "./App.css";
import logo from "./logo.png";
import operator from "./assets/bot.svg";

const sendUserMessage = async (message, language, conversationID) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://demo.cloudwind-alliance-staging.com/api/ask",
    timeout: 10000,
    headers: {},
    params: {
      question: message,
      language: language,
      conversation_id: conversationID,
    },
  };

  const response = axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        console.log("The request timed out.");
        return "An error occurred while processing your message. Please try again later.";
      } else {
        console.error("Error while sending message:", error);
        return "An error occurred while processing your message. Please try again later.";
      }
    });
  return response;
};

const WidgetTitle = (
  <div className="widget-title-container">
    <div className="logo-container">
      <img src={logo} alt="Logo" className="logo" />
      <span className="online-indicator"></span>
    </div>
  </div>
);

const MESSAGES = {
  English: {
    help: "Do you need any more help?",
    feedback: "Thank you for your feedback",
    rateExperience: "Please rate your experience:",
    greeting: "Welcome! How can I help you?",
    placeholder: "type a message",
  },
  French: {
    help: "Avez-vous besoin d'autre aide?",
    feedback: "Merci pour votre avis!",
    rateExperience: "Veuillez évaluer votre expérience:",
    greeting: "Bienvenue! Comment puis-je t'aider?",
    placeholder: "Tapez votre question...",
  },
  Arabic: {
    help: "هل تحتاج إلى المزيد من المساعدة؟",
    feedback: "شكرًا على ملاحظاتك",
    rateExperience: "نأمل أن نكون قد أجبنا على استفساراتك",
    greeting: "مرحبًا! كيف يمكنني مساعدتك؟",
    placeholder: "...ما ترغب في معرفته؟",
  },
  // ... add more languages and messages as needed
};

const generateConversationID = () => {
  const timestamp = new Date().getTime();
  const randomString = Math.random().toString(36).substring(2, 15);
  return `${timestamp}-${randomString}`;
};

const LanguageSelector = ({ handleLanguageChange, selectedLanguage }) => {
  return (
    <div className="language-selector-container">
      <select
        className="language-selector"
        value={selectedLanguage}
        onChange={handleLanguageChange}
      >
        <option value="English">English</option>
        <option value="French">French</option>
        <option value="Arabic">Arabic</option>
      </select>
    </div>
  );
};

function App() {
  const [lastUserMessageTime, setLastUserMessageTime] = useState(0);
  const [selectedRating, setSelectedRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0); // Added state for hovered rating
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [promptedForRating, setPromptedForRating] = useState(false);
  const [language, setLanguage] = useState("French");
  const [ratingComponentRef, setRatingComponentRef] = useState(null);
  const [conversationID, setConversationID] = useState(
    generateConversationID()
  );

  const handleRatingClick = (rating) => {
    console.log(`User selected rating: ${rating}`);
    setSelectedRating(rating);
    deleteMessages(1, "ratingComponent");
    addResponseMessage(MESSAGES[language].feedback);
  };

  const handleLanguageChange = useCallback((e) => {
    setLanguage(e.target.value);
  }, []);

  const CustomRatingButton = React.memo(
    ({ handleRatingClick, selectedRating, hoveredRating }) => (
      <div className="rating-card">
        <span className="rating-text">{MESSAGES[language].rateExperience}</span>
        <div className="star-rating">
          {[1, 2, 3, 4, 5].map((rating) => (
            <span
              key={rating}
              className={`star ${
                rating <= (selectedRating || hoveredRating) ? "selected" : ""
              }`}
              onClick={() => handleRatingClick(rating)}
              onMouseEnter={() => handleRatingHover(rating)}
              onMouseLeave={handleRatingHoverOut}
              role="img"
              aria-label={`Star ${rating}`}
            >
              {rating <= hoveredRating
                ? "🟊"
                : rating <= selectedRating
                ? "★"
                : "☆"}
            </span>
          ))}
        </div>
      </div>
    )
  );

  // const ratingRef = renderCustomComponent(CustomRatingButton, {
  //   handleRatingClick: handleRatingClick,
  //   selectedRating: selectedRating,
  //   hoveredRating: hoveredRating,
  // });

  // useEffect(() => {
  //   setRatingComponentRef(ratingRef);
  // }, []);

  const handleRatingHover = (rating) => {
    setHoveredRating(rating); // Update the hovered rating
  };

  const handleRatingHoverOut = () => {
    setHoveredRating(0); // Reset the hovered rating when user hovers out
  };

  const handleQuickButton = (value) => {
    if (MESSAGES[value]) {
      addUserMessage(value); // Display the user's chosen language as a message.
      addResponseMessage(MESSAGES[value].greeting);
      setLanguage(value);
      setQuickButtons([]); // Clear the quick buttons after a choice is made.
    }
  };

  const handleNewUserMessage = async (newMessage) => {
    // // If the user's message matches a language:
    // if (Object.keys(MESSAGES).includes(newMessage)) {
    //   addResponseMessage(MESSAGES[newMessage].greeting);
    //   return;
    // }

    toggleInputDisabled(true);
    toggleMsgLoader(true);

    const currentTime = Date.now();
    const timeSinceLastMessage = currentTime - lastUserMessageTime;

    // setLastActivity(Date.now());

    setLastUserMessageTime(currentTime);
    setLastActivity(Date.now());

    const responseMessage = await sendUserMessage(
      newMessage,
      language,
      conversationID
    );
    addResponseMessage(responseMessage);

    toggleInputDisabled(false);
    toggleMsgLoader(false);
  };
  useEffect(() => {
    // If language is set, clear the quick buttons.
    setQuickButtons([]);
  }, [language]);

  useEffect(() => {
    toggleInputDisabled(true);
    toggleMsgLoader(true);

    setTimeout(() => {
      // Display a message to the user to select a language.
      addUserMessage("Please choose a language");

      // Present the language options using quick buttons.
      const quickButtons = Object.keys(MESSAGES).map((lang) => ({
        label: lang,
        value: lang,
      }));
      setQuickButtons(quickButtons);
      toggleInputDisabled(false);
      toggleMsgLoader(false);
    }, 2000);
  }, []);

  const renderRatingComponent = () => {
    if (!ratingComponentRef) {
      // Only render if the reference doesn't exist.
      const ref = renderCustomComponent(
        CustomRatingButton,
        {
          handleRatingClick: handleRatingClick,
          handleRatingHover: handleRatingHover, // Make sure this line is here
          handleRatingHoverOut: handleRatingHoverOut, // And this one
          selectedRating: selectedRating,
          hoveredRating: hoveredRating,
          language: language,
        },
        false,
        "ratingComponent"
      );
      setRatingComponentRef(ref);
    }
  };

  useEffect(() => {
    let inactivityTimeout;
    let ratingPromptTimeout;

    const checkForInactivity = () => {
      const currentTime = Date.now();
      const timeSinceLastActivity = currentTime - lastActivity;

      if (timeSinceLastActivity > 60000 && !promptedForRating) {
        // Removed `&& !promptedForRating` as it was preventing the prompt after the user sent a message
        addResponseMessage(MESSAGES[language].help);
        setPromptedForRating(true); // To prevent further prompts until reset
        setLastActivity(currentTime);

        ratingPromptTimeout = setTimeout(() => {
          renderRatingComponent();
        }, 30000);
      }
    };

    inactivityTimeout = setInterval(checkForInactivity, 10000);

    return () => {
      clearInterval(inactivityTimeout);
      clearTimeout(ratingPromptTimeout);
    };
  }, [lastActivity, promptedForRating, language]);

  return (
    <div className="App">
      <div className="chat-container">
        {/* <LanguageSelector
          handleLanguageChange={handleLanguageChange}
          selectedLanguage={language}
        /> */}

        <Widget
          handleNewUserMessage={handleNewUserMessage}
          handleQuickButtonClicked={handleQuickButton}
          profileAvatar={operator}
          title={WidgetTitle}
          subtitle={false}
          senderPlaceHolder={MESSAGES[language].placeholder}
          showCloseButton={true}
          fullScreenMode={false}
          autofocus={true}
          showTimeStamp={true}
          emojis={true}
          showBadge={true}
        />
      </div>
    </div>
  );
}

export default App;
