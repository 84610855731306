import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const loadWidget = () => {
  const widgetContainer = document.createElement('div');
  widgetContainer.id = 'my-chat-widget-container';
  document.body.appendChild(widgetContainer);

  ReactDOM.render(<App />, widgetContainer);
};

(function() {
    loadWidget();
})();